<template>
  <div>
    <div class="mb-3">
      <v-text-field
        v-model="code"
        :label="label"
        ref="inputScanRef"
        autofocus
        dense
        outlined
        clearable
        hide-details
        @keyup.enter="inputScan"
      ></v-text-field>
    </div>

    <PackingItems
      v-if="order && order.id"
      :key="`pi_${order.id}`"
      :order="order"
      :command="command"
      :basket="basket"
      :scanned-items="scanned"
      :packaging="packaging"
      :last-id-goods="lastIdGoods"
      :is-missing="isMissing"
      @removePackaging="removePackaging"
    />

    <v-dialog v-model="dialogConfirm" persistent max-width="350px">
      <ConfirmBox
        v-if="dialogConfirm"
        :title="$t('labels.finish_basket_one')"
        :description="$t('messages.finish_basket_one')"
        :label="$t('labels.scan_basket_to_confirm')"
        :placeholder="$t('labels.scan_basket_to_confirm')"
        :codes="checkingCodes"
        @cancel="hideConfirmDialog"
        @confirm="finishBasket1Sku"
      />
    </v-dialog>

    <v-dialog v-model="dialogAddItemToPack" persistent max-width="350px">
      <PackingAddItemToPack
        v-if="dialogAddItemToPack"
        :order="order"
        @cancel="hideAddItemToPackDialog"
        @confirm="addItemsToPack"
      />
    </v-dialog>

    <v-dialog v-model="dialogSelectSKu" persistent max-width="800px">
      <PackingSelectSku
        v-if="dialogSelectSKu"
        :list-goods="duplicateSkus"
        @cancel="hideSelectSkuDialog"
        @confirm="confirmSelectSku"
      />
    </v-dialog>
  </div>
</template>

<script>
import packingMixins from "@/components/goods_issue/mixins/packingMixins";

export default {
  name: "PackingType2",
  components: {
    PackingItems: () => import("@/components/goods_issue/PackingItems"),
    ConfirmBox: () => import("@/components/common/ConfirmBox"),
    PackingSelectSku: () => import("@/components/goods_issue/PackingSelectSku"),
    PackingAddItemToPack: () =>
      import("@/components/goods_issue/PackingAddItemToPack"),
  },
  mixins: [packingMixins],
  data: () => ({}),
};
</script>

<style scoped></style>
