import { render, staticRenderFns } from "./PackingType2.vue?vue&type=template&id=592643d2&scoped=true&"
import script from "./PackingType2.vue?vue&type=script&lang=js&"
export * from "./PackingType2.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "592643d2",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VDialog,VTextField})
